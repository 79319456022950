import { searchInput, prefilledTerm } from "@Constants";
import { SET_NEW_INPUT } from "@Constants/actionTypes";

const setSearchInput = (stateInput, dispatch, value, category) => {
  let data = {};
  if (prefilledTerm && !stateInput.doneLoading) {
    data = {
      ...stateInput,
      doneLoading: true,
      data: {
        ...stateInput.data,
        quality: {},
        company: value,
        userInput: true,
        landingspage: true,
      },
    };
  } else if (searchInput && !stateInput.doneLoading) {
    const inputData = JSON.parse(searchInput);
    data = {
      ...stateInput,
      doneLoading: true,
      data: { ...inputData, landingspage: false },
    };
  } else {
    switch (category) {
      case "place":
        data = {
          ...stateInput,
          doneLoading: true,
          data: {
            ...stateInput.data,
            place: value.Name,
            userInput: true,
            landingspage: false,
          },
        };
        break;
      case "company":
        if (value.searchCategory === "quality") {
          data = {
            ...stateInput,
            doneLoading: true,
            data: {
              ...stateInput.data,
              quality: value,
              company: {},
              userInput: true,
              landingspage: false
            },
          };
        } else {
          data = {
            ...stateInput,
            doneLoading: true,
            data: {
              ...stateInput.data,
              company: value,
              userInput: true,
              landingspage: false
            },
          };
        }
        break;
      case "quality":
        data = {
          ...stateInput,
          doneLoading: true,
          data: {
            ...stateInput.data,
            quality: value,
            userInput: true,
            landingspage: false
          },
        };
        break;
      case "distance":
        data = {
          ...stateInput,
          doneLoading: true,
          data: {
            ...stateInput.data,
            distance: value,
            userInput: true,
            landingspage: false
          },
        };
        break;
      default:
        data = {
          ...stateInput,
          doneLoading: true,
          data: {
            place: "", //name
            company: {}, //name & id
            quality: {},
            distance: 10,
            userInput: false,
            landingspage: false,
          },
        };
    }
  }

  dispatch({
    type: SET_NEW_INPUT,
    payload: data,
  });

  sessionStorage.setItem("TNLZSInput", JSON.stringify(data.data));
};

const clearSearchInput = (dispatch) => {
  const data = {
    loading: false,
    doneLoading: true,
    data: {
      place: "", //name
      company: {}, //name & id
      quality: {},
      distance: 10,
      userInput: false,
      landingspage: false,
    },
  };
  dispatch({
    type: SET_NEW_INPUT,
    payload: data,
  });
  sessionStorage.setItem("TNLZSInput", JSON.stringify(data.data));
};

export { setSearchInput, clearSearchInput };
