import React from "react";
import { Certificates } from "./Certificates";

const CompanyCard = ({
  companyDetail,
  checkWebsite,
  toggleMoreInfo,
  togglePopup,
  getCertificates,
}) => {
  const keyLoc = "Loc.";

  const breakStyle = {
    backgroundColor: "#ddd",
    padding: "1rem",
    margin: "1rem 0 4rem 0",
    fontWeight: "700",
    textAlign: "center",
  };

  const kv = companyDetail.RateUrl
    ? companyDetail.RateUrl.indexOf("klantenvertellen") > -1
    : false;

  function checkForAmount(amount) {
    console.log(parseInt(amount));
    return parseInt(companyDetail.RateAmount) < 5;
  }

  if (companyDetail.Name === "break") {
    return (
      <>
        {companyDetail.resultsLeft > 0 && (
          <div className="result break" style={breakStyle}>
            Nog {companyDetail.resultsLeft} resultaten zonder beoordeling van
            Klanten Vertellen (in willekeurige volgorde)
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className="result">
        <div className="add-to-mail">
          <a href="#" data-remove-if-empty="ignore" />
        </div>
        <div className="company-detail">
          <div className="details">
            <div style={{ display: "none" }}>{companyDetail.relevantScore}</div>
            <h3>{companyDetail.Name}</h3>
            {companyDetail.AltName && companyDetail.AltName.length > 0 && (
              <>
                <span className="alternative-names">
                  ({companyDetail.AltName})
                </span>
              </>
            )}

            {checkWebsite(companyDetail).length > 0 && (
              <div className="website">
                <a
                  href={checkWebsite(companyDetail)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {companyDetail.Site || companyDetail.url_1}
                </a>
              </div>
            )}
            {companyDetail.Mail.length > 0 && (
              <div className="mail">
                <a
                  href={`mailto:${companyDetail.Mail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {companyDetail.Mail}
                </a>
              </div>
            )}
            {companyDetail.Phone.length > 0 && (
              <div className="telephone">
                <a
                  href={`tel:${companyDetail.Phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {companyDetail.Phone}
                </a>
              </div>
            )}
            {companyDetail[keyLoc].length > 0 && (
              <div className="address">
                <a
                  href={`https://www.google.nl/maps/place/${companyDetail[keyLoc]}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {companyDetail[keyLoc]}
                </a>
              </div>
            )}
            {companyDetail.Rate !== null && (
              <div className="mobile-rating">
                {companyDetail.Rate !== null &&
                  companyDetail.RateUrl !== null &&
                  companyDetail.RateUrl.length > 0 &&
                  companyDetail.Rate.length > 0 && (
                    <a
                      href={companyDetail.RateUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {companyDetail.Rate} Reviews bekijken
                    </a>
                  )}
              </div>
            )}
            {companyDetail.RateUrl !== null &&
              (companyDetail.Rate === null || companyDetail.Rate === "") && (
                <div className="mobile-rating">
                  <a
                    href={companyDetail.RateUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {kv && <>Geef beoordeling</>}
                    {!kv && <>Reviews bekijken</>}
                  </a>
                </div>
              )}
          </div>

          <div
            className="certificates"
            onClick={(e) =>
              togglePopup(getCertificates(companyDetail.Kwaliteitreg))
            }
          >
            <h4>Certificering</h4>
            <Certificates
              certificates={getCertificates(companyDetail.Kwaliteitreg)}
            />
          </div>
        </div>
        <div className="rating">
          <div className="number">
            {(companyDetail.Rate === null || checkForAmount(companyDetail.RateAmount))
              ? "-"
              : companyDetail.Rate.length > 0
              ? companyDetail.Rate
              : "-"}
          </div>
          <div className="number-detail">
            {companyDetail.Rate !== null &&
              companyDetail.RateUrl !== null &&
              companyDetail.RateUrl.length > 0 &&
              companyDetail.Rate.length > 0 && (
                <strong>
                {checkForAmount(companyDetail.RateAmount) ? (
                  <>Minder dan 5 reviews<br/></>
                ) : (
                  <>{companyDetail.RateAmount} reviews<br/></>
                )}
                  <a
                    href={companyDetail.RateUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reviews bekijken
                  </a>
                </strong>
              )}
            {companyDetail.RateUrl !== null &&
              (companyDetail.Rate === null || companyDetail.Rate === "") &&
              companyDetail.RateUrl.length > 0 && (
                <strong>
                  <a
                    href={companyDetail.RateUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {kv && <>Geef beoordeling</>}
                    {!kv && <>Reviews bekijken</>}
                  </a>
                </strong>
              )}
          </div>
        </div>
        <div
          className="mobile-btn-more-info"
          onClick={(e) => toggleMoreInfo(e)}
        >
          <span className="show-more">Toon meer</span>
          <span className="show-less">Toon minder</span>
        </div>
      </div>
    );
  }
};

export { CompanyCard };

/*

        */
