import React, { createContext, useReducer } from "react";
import { searchInitialState } from "./searchInitialState";
import { SearchReducer } from "@Context/reducers/search";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [searchState, searchDispatch] = useReducer(SearchReducer, searchInitialState);
  return (
    <GlobalContext.Provider value={{ searchState, searchDispatch }}>
      {children}
    </GlobalContext.Provider>
  );
/*
  const state = updatedState(initState, dispatch);

  const actions = combineActions(dispatch, state);

  return (
    <StoreContext.Provider value={{ state, actions }}>
      {children}
    </StoreContext.Provider>
  );*/
};

export { GlobalContext, GlobalProvider };
