const searchInitialState = {
  places: {
    loading: false,
    doneLoading: false,
    data: []
  },
  filterData: {
    loading: false,
    doneLoading: false,
    data: {}
  },
  details: {
    loading: false,
    doneLoading: false,
    data: []
  },
  suggestions: {
    loading: false,
    doneLoading: false,
    data: {}
  },
  input: {
    loading: false,
    doneLoading: false,
    data: {
      place: "",
      company: {},
      quality: {},
      distance: 10,
      userInput: false,
      landingspage: false
    }
  },
  qualities: {
    loading: false,
    doneLoading: false,
    data: []
  },
  latlong: {
    loading: false,
    doneLoading: false,
    data: {}
  },
  results: {
    loading: false,
    doneLoading: false,
    data: {
      qualities: [],
      list: []
    }
  }
  
};

export { searchInitialState };
