import React, { useContext, useState, useEffect } from "react";

const Autocomplete = ({
  placeholder,
  suggestions,
  filterID,
  storeInput,
  defaultValueForInput,
  useGeo,
  tooltipIcon
}) => {
  const [autocompleteData, setAutocompleteData] = useState({
    userInput: defaultValueForInput ? defaultValueForInput : "",
    searchCategory: "",
    allSuggestions: suggestions,
    currentSuggestions: [],
    data: [],
    Id: 0,
    init: true,
  });

  let timeOutId;

  useEffect(() => {
    if (!autocompleteData.init) {
      storeInput(
        {
          Name: autocompleteData.userInput,
          searchCategory: autocompleteData.searchCategory,
          Id: autocompleteData.Id,
          data: autocompleteData.data
        },
        filterID
      );
    }
  }, [autocompleteData]);

  function SuggestionListAll() {
    let tempList = [];
    suggestions.map((suggestion) =>
      Object.values(suggestion)[0].map((data) => {
        tempList.push(data);
      })
    );
    return tempList;
  }

  function checkValue(inputValue) {
    return function(element) {
      return element.Name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    };
  }

  function filterSuggestions(inputValue) {
    return autocompleteData.allSuggestions
      .filter(checkValue(inputValue))
      .sort((a, b) => a.Name - b.Name);
  }

  function onChangeInput(elem) {
    elem.target.value.length > 3
      ? setAutocompleteData({
          ...autocompleteData,
          userInput: elem.target.value,
          currentSuggestions: filterSuggestions(elem.target.value),
          searchCategory: "wildcard",
          init: false,
          Id: "",
        })
      : setAutocompleteData({
          ...autocompleteData,
          userInput: elem.target.value,
          currentSuggestions: [],
          searchCategory: "wildcard",
          init: false,
          Id: "",
        });
  }

  function lostFocus(e) {
    timeOutId = setTimeout(() => {
      setAutocompleteData({
        ...autocompleteData,
        currentSuggestions: [],
      });
    });
  }

  function getFocus() {
    clearTimeout(timeOutId);
  }

  function suggestionPicked(name, category, ID, data, dataKw) {
    let dataCombined;
    if (data && dataKw) {
      dataCombined = Array.from(new Set(data.concat(dataKw))).filter(x => !!x);
    }
    console.log('dataCombined', dataCombined);
    storeInput({ Name: name, searchCategory: category, Id: ID, data: dataCombined }, filterID);
    setAutocompleteData({
      ...autocompleteData,
      userInput: name,
      searchCategory: category,
      currentSuggestions: [],
      data: dataCombined ? dataCombined : [],
      Id: ID,
      init: false,
    });
    clearTimeout(timeOutId);
  }

  function iconClick(e) {
    if (useGeo) {
      getLocation(e);
    }
  }

  function getLocation(e) {
    const inputElem = e.target.parentNode.querySelector("input");
    if (navigator.geolocation) {
      inputElem.value = "Bezig met locatie ophalen";
      navigator.geolocation.getCurrentPosition(
        function(position) {
          inputElem.value =
            position.coords.latitude + " " + position.coords.longitude;

          setAutocompleteData({
            ...autocompleteData,
            userInput:
              position.coords.latitude + " " + position.coords.longitude,
            searchCategory: "Places",
            currentSuggestions: [],
            Id: "",
            init: false,
          });
          storeInput(
            {
              Name: position.coords.latitude + " " + position.coords.longitude,
              searchCategory: "Places",
              Id: "",
            },
            filterID
          );
        },
        function locationError(error) {
          inputElem.value = "";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert("Geolocation data is niet gevonden");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("Kan huidige locatie niet vinden");
              break;
            case error.TIMEOUT:
              alert("Locatiebepaling duurt te lang");
              break;
            default:
              alert("Error in geolocation data");
              break;
          }
        }
      );
    }
  }

  return (
    <>
      <div
        onBlur={(e) => lostFocus(e)}
        onFocus={(e) => getFocus(e)}
        style={{ width: "100%" }}
      >
        {suggestions && (
          <input
            id={`inputFor${filterID}`}
            type="text"
            value={autocompleteData.userInput}
            placeholder={placeholder}
            autoComplete="off"
            onChange={(e) => onChangeInput(e)}
          />
        )}
        {autocompleteData.currentSuggestions.length > 0 && (
          <div className="autocomplete-suggestions">
            <hr />
            <div className="content">
              <ul>
                {autocompleteData.currentSuggestions.map((data, index) => (
                  <li
                    key={index}
                    onMouseDown={(e) =>
                      suggestionPicked(data.Name, data.searchCategory, data.Id, data.auto_kwaliteitsregelingIds, data.kwaliteitsregelingId)
                    }
                  >
                    <span>{data.Name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <span title={tooltipIcon} className="icon" data-remove-if-empty="ignore" onClick={iconClick}>
        
      </span>
    </>
  );
};

export { Autocomplete };

/*
 */
