export const PLACES_LOADING = "PLACES_LOADING";
export const PLACES_LOAD_SUCCESS = "PLACES_LOAD_SUCCESS";
export const PLACES_LOAD_ERROR = "PLACES_LOAD_ERROR";
export const FILTERDATA_LOADING = "FILTERDATA_LOADING";
export const FILTERDATA_LOAD_SUCCESS = "FILTERDATA_LOAD_SUCCESS";
export const FILTERDATA_LOAD_ERROR = "FILTERDATA_LOAD_ERROR";
export const DETAILS_LOADING = "DETAILS_LOADING";
export const DETAILS_LOAD_SUCCESS = "DETAILS_LOAD_SUCCESS";
export const DETAILS_LOAD_ERROR = "DETAILS_LOAD_ERROR";
export const SEARCHINPUT_LOADING = "SEARCHINPUT_LOADING";
export const SEARCHINPUT_LOAD_SUCCESS = "SEARCHINPUT_LOAD_SUCCESS";
export const SEARCHINPUT_LOAD_ERROR = "SEARCHINPUT_LOAD_ERROR";
export const SUGGESTIONS_LOADING = "SUGGESTIONS_LOADING";
export const SUGGESTIONS_LOAD_SUCCESS = "SUGGESTIONS_LOAD_SUCCESS";
export const SET_NEW_INPUT = "SET_NEW_INPUT";
export const QUALITIES_LOADING = "QUALITIES_LOADING";
export const QUALITIES_LOAD_SUCCESS = "QUALITIES_LOAD_SUCCESS";
export const RESULTS_LOADING = "RESULTS_LOADING";
export const RESULTS_LOAD_SUCCESS = "RESULTS_LOAD_SUCCESS";
export const LAT_LONG_LOAD_SUCCESS = "LAT_LONG_LOAD_SUCCESS";
