import React from "react";
import {
  Route,
  Switch,
  Redirect,
  HashRouter as Router,
} from "react-router-dom";
import { EntryPoint } from "./pages";

const App = ({ loadModule }) => {
  switch (loadModule) {
    case "Results":
      return (
        <>
          <EntryPoint module="results" />
        </>
      );
    case "Search":
      return <EntryPoint module="search" />;
    default:
      return (
        <Router>
          <Switch>
            <Route
              path="/membersearch/search"
              render={() => <EntryPoint module="search" />}
            />
            <Route
              path="/membersearch/results"
              render={() => <EntryPoint module="results" />}
            />
            <Redirect exact from="/" to="/membersearch/search" />
          </Switch>
        </Router>
      );
  }
};

export { App };
