import {
  PLACES_LOADING,
  PLACES_LOAD_ERROR,
  PLACES_LOAD_SUCCESS,
  FILTERDATA_LOADING,
  FILTERDATA_LOAD_ERROR,
  FILTERDATA_LOAD_SUCCESS,
  DETAILS_LOADING,
  DETAILS_LOAD_ERROR,
  DETAILS_LOAD_SUCCESS,
  SUGGESTIONS_LOADING,
  SUGGESTIONS_LOAD_SUCCESS,
  SET_NEW_INPUT,
  QUALITIES_LOADING,
  QUALITIES_LOAD_SUCCESS,
  RESULTS_LOADING,
  RESULTS_LOAD_SUCCESS,
  LAT_LONG_LOAD_SUCCESS
} from "../../constants/actionTypes";

const SearchReducer = (state, { payload, type }) => {
  switch (type) {
    case PLACES_LOADING: {
      return {
        ...state,
        places: {
          ...state.places,
          loading: true,
        },
      };
    }
    case PLACES_LOAD_SUCCESS: {
      return {
        ...state,
        places: {
          loading: false,
          doneLoading: true,
          data: payload,
        },
      };
    }
    case PLACES_LOAD_ERROR: {
      return {
        ...state,
        places: {
          ...state.places,
          loading: false,
          doneLoading: true,
          error: {
            payload,
          },
        },
      };
    }
    case FILTERDATA_LOADING: {
      return {
        ...state,
        filterData: {
          ...state.filterData,
          loading: true,
        },
      };
    }
    case FILTERDATA_LOAD_SUCCESS: {
      return {
        ...state,
        filterData: {
          loading: false,
          doneLoading: true,
          data: payload,
        },
      };
    }
    case FILTERDATA_LOAD_ERROR: {
      return {
        ...state,
        filterData: {
          ...state.filterData,
          loading: false,
          doneLoading: true,
          error: {
            payload,
          },
        },
      };
    }
    case DETAILS_LOADING: {
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      };
    }
    case DETAILS_LOAD_SUCCESS: {
      return {
        ...state,
        details: {
          loading: false,
          doneLoading: true,
          data: payload,
        },
      };
    }
    case DETAILS_LOAD_ERROR: {
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          doneLoading: true,
          error: {
            payload,
          },
        },
      };
    }
    case SUGGESTIONS_LOADING: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: true,
        },
      };
    }
    case SUGGESTIONS_LOAD_SUCCESS: {
      return {
        ...state,
        suggestions: {
          loading: false,
          doneLoading: true,
          data: payload,
        },
      };
    }
    case SET_NEW_INPUT: {
      return {
        ...state,
        input: payload
      }
    }
    case QUALITIES_LOADING: {
      return {
        ...state,
        qualities: {
          ...state.qualities,
          loading: true,
        },
      }
    }
    case QUALITIES_LOAD_SUCCESS: {
      return {
        ...state,
        qualities: {
          ...state.qualities,
          loading: false,
          doneLoading: true,
          data: payload
        },
      }
    }
    case LAT_LONG_LOAD_SUCCESS: {
      return {
        ...state,
        latlong: {
          ...state.latlong,
          loading: false,
          doneLoading: true,
          data: payload
        },
      }
    }
      
      
    case RESULTS_LOADING: {
      return {
        ...state,
        results: {
          ...state.results,
          loading: true
        }
      }
    }
    case RESULTS_LOAD_SUCCESS: {
      return {
        ...state,
        results: {
          loading: false,
          doneLoading: true,
          data: payload
        }
      }
    }
    default:
      return state;
  }
};

export { SearchReducer };
