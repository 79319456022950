import { baseUrl, token, filterDataFromStorage } from "@Constants";
import {
  FILTERDATA_LOADING,
  FILTERDATA_LOAD_ERROR,
  FILTERDATA_LOAD_SUCCESS,
} from "@Constants/actionTypes";
import { CONNECTION_ERROR } from "@Constants/API";

require("es6-promise").polyfill();
require("isomorphic-fetch");

export const getFilterData = (path) => (dispatch) => {
  dispatch({
    type: FILTERDATA_LOADING,
  });
  if (filterDataFromStorage) {
    setFilterData(dispatch, JSON.parse(filterDataFromStorage));
  } else {
    fetch(baseUrl + path + "?token=" + token)
      .then((response) => response.json())
      .then((responseData) => {
        responseData.data.MappingKeywordAttribute.map((spec) => {
          spec.auto_kwaliteitsregelingIds = spec.auto_kwaliteitsregelingIds
            ? spec.auto_kwaliteitsregelingIds
                .split(",")
                .map((specId) => parseInt(specId))
            : [];

          let uniquesA = new Set(spec.auto_kwaliteitsregelingIds);
          spec.auto_kwaliteitsregelingIds = [...uniquesA];
            
          spec.kwaliteitsregelingId =
            spec.kwaliteitsregelingId === null
              ? spec.kwaliteitsregelingId
              : spec.kwaliteitsregelingId = [...spec.auto_kwaliteitsregelingIds, ...spec.kwaliteitsregelingId
                  .split(",")
                  .map((number) => parseInt(number))];
          let uniquesB = new Set(spec.kwaliteitsregelingId);
          spec.kwaliteitsregelingId = [...uniquesB];

          spec.specialismeId =
            spec.specialismeId === null
              ? spec.kwaliteitsregelingId
              : spec.specialismeId;
          return spec;
        });
        setFilterData(dispatch, responseData.data);
        setFilterDataStorage(responseData.data);
      })
      .catch((err) => {
        dispatch({
          type: FILTERDATA_LOAD_ERROR,
          payload: err.data ? err.data : CONNECTION_ERROR,
        });
      });
  }
};

const setFilterData = (dispatch, data) => {
  dispatch({
    type: FILTERDATA_LOAD_SUCCESS,
    payload: data,
  });
};

const setFilterDataStorage = (data) => {
  sessionStorage.setItem("TNLZSFilterdata", JSON.stringify(data));
};
