import React, { useEffect, useContext, useState } from "react";
import { GlobalContext } from "@Context/StoreContext";
import { Search, Results, ExtendedSearch } from "@Pages/";
import { getPlaces } from "@Context/actions/search/getPlaces";
import { getFilterData } from "@Context/actions/search/getFilterData";
import { getDetails } from "@Context/actions/search/getDetails";
import { createSuggestionList } from "@Context/actions/search/createSuggestionList";
import {
  setSearchInput,
  clearSearchInput,
} from "@Context/actions/search/setSearchInput";
import { setResultList } from "@Context/actions/search/setResultList";
import { extendedSearchRoot, prefilledTerm } from "@Constants";

const EntryPoint = ({ module }) => {
  const { searchState, searchDispatch } = useContext(GlobalContext);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    !searchState.places.doneLoading && !searchState.places.loading
      ? getPlaces("places.json")(searchDispatch)
      : null;

    !searchState.filterData.doneLoading && !searchState.filterData.loading
      ? getFilterData("GetInfoForFiltering.aspx")(searchDispatch)
      : null;

    searchState.places.doneLoading &&
    searchState.filterData.doneLoading &&
    !searchState.suggestions.loading &&
    !searchState.suggestions.doneLoading
      ? createSuggestionList(
          searchState.places.data,
          searchState.filterData.data,
          searchDispatch
        )
      : null;

    !searchState.details.doneLoading && !searchState.details.loading
      ? getDetails("GetDetails.aspx")(searchDispatch)
      : null;

    if (!searchState.input.doneLoading && searchState.suggestions.doneLoading) {
      module === "search"
        ? clearSearchInput(searchDispatch)
        : checkLandingsPage();
    }

    if (searchState.suggestions.doneLoading) {
      setDataLoaded(true);
    }
  }, [searchState]);

  function checkLandingsPage() {
    if (prefilledTerm) {
      const specToSearch = prefilledTerm.innerText.toLowerCase().trim();
      const specID = searchState.filterData.data.MappingKeywordAttribute.filter(
        (spec) => {
          return spec.Name.toLowerCase().trim() === specToSearch;
        }
      );
      if (specID.length) {
        setSearchInput(
          searchState.input,
          searchDispatch,
          {
            Name: prefilledTerm.innerText,
            Id: specID[0].Id,
            searchCategory: specID[0].searchCategory,
            data: specID[0].auto_kwaliteitsregelingIds,
          },
          "company"
        );
      }
    } else {
      setSearchInput(searchState.input, searchDispatch);
    }
  }

  return (
    <>
      {dataLoaded && module === "search" && (
        <section className="member-search">
          <Search />
        </section>
      )}
      {dataLoaded && searchState.details.doneLoading && module === "results" && (
        <>
          <section className="member-search">
            <Results />
          </section>
          {extendedSearchRoot && !prefilledTerm && <ExtendedSearch />}
        </>
      )}
      {!dataLoaded && (
        <section className="member-search">
          <section className="search-box">
            <p>Bezig met laden...</p>
          </section>
        </section>
      )}
    </>
  );
};

export { EntryPoint };

/*

*/
