import { baseUrl, prefilledTerm } from "@Constants";

const storeSearch = (searchState, listLength) => {
  if (!prefilledTerm) {
    const dataToSend = {
      dateTime: formateDate(),
      location: searchState.input.data.place,
      radiusInKm: searchState.input.data.distance,
      search: searchState.input.data.company.Name
        ? searchState.input.data.company.Name
        : "",
      qualityControls: searchState.input.data.quality.Id
        ? searchState.input.data.quality.Id
        : "",
      searchResults: listLength,
    };

    let formData = new FormData();
    for (const [key, value] of Object.entries(dataToSend)) {
      formData.append(key, value);
    }

    return fetch(baseUrl + "PostSearchCriteria.aspx", {
      method: "POST",
      body: formData,
    });
  }
};

function formateDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minutes = d.getMinutes();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  if (hour.length < 2) {
    hour = "0" + hour;
  }

  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }

  return [year, month, day].join("-") + " " + [hour, minutes].join(":");
}

export { storeSearch };
