import { getStorageValue } from "@Lib/storage";

export const baseUrl =
  process.env.API_URL + "/cms/modules/Uneto/ledenzoek2020/";
export const token = "0622D836-AA7B-4B50-AB1B-8D29BED1A103";

export const extendedSearchRoot = document.getElementById("appExtendedSearch");
export const prefilledTerm = document.getElementById("prefilled-term");
export const placesFromStorage = getStorageValue("TNLZSPlaces", "local");
export const filterDataFromStorage = getStorageValue("TNLZSFilterdata");
export const detailsFromStorage = getStorageValue("TNLZSDetails");
export const searchInput = getStorageValue("TNLZSInput");
export const SuggestionsFromStorage = getStorageValue("TNLZSSuggestions");
