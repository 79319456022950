import {
  SUGGESTIONS_LOADING,
  SUGGESTIONS_LOAD_SUCCESS,
} from "@Constants/actionTypes";
import { sortList } from "@Lib/sorting";

export const createSuggestionList = (places, filterData, dispatch) => {
  dispatch({
    type: SUGGESTIONS_LOADING,
  });
  const companies = filterData.Companies.filter(
    (v, i, a) => a.findIndex((t) => t.Name === v.Name) === i
  ).map((company) => {
    company.searchCategory = "company";
    return company;
  });

  const quality = filterData.Kwaliteitsregelingen.map((quality) => {
    quality.searchCategory = "quality";
    return quality;
  });

  const specialism = filterData.MappingKeywordAttribute.map((special) => {
    delete Object.assign(special, { Name: special.Keyword }).Keyword;
    delete Object.assign(special, { Id: special.specialismeId }).specialismeId;
    //special.auto_kwaliteitsregelingIds = [];
    special.searchCategory = "specialism";
    return special;
  });

  const list = {
    places: places,
    companies: [...specialism, ...sortList(quality), ...sortList(companies)],
  };

  setSuggestions(dispatch, list);
  setSuggestionsStorage(list);
};

const setSuggestions = (dispatch, data) => {
  dispatch({
    type: SUGGESTIONS_LOAD_SUCCESS,
    payload: data,
  });
};

const setSuggestionsStorage = (data) => {
  sessionStorage.setItem("TNLZSSuggestions", JSON.stringify(data));
};
