import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from "./context/StoreContext";
import { App } from "./App";
import "./scss/app.scss";

const appID = process.env.Module;
const docAppId = appID === "dev" ? "#appSearch" : "#app" + appID;

const RootHTML = () => {
  if (appID === "dev") {
    return (
      <BrowserRouter>
        <GlobalProvider>
          <App loadModule={appID}/>
        </GlobalProvider>
      </BrowserRouter>
    );
  } else {
    return (
      <GlobalProvider>
        <App loadModule={appID}/>
      </GlobalProvider>
    );
  }
};

render(<RootHTML />, document.querySelector(docAppId));
