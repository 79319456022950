import React, { useEffect, useContext, useState } from "react";
import { GlobalContext } from "@Context/StoreContext";
import { ResultsList } from "../../components/componentsList";
import { setQualities } from "@Context/actions/search/setQualities";
import { getLatLong, setLatLong } from "@Context/actions/search/latlong";
import { storeSearch } from "@Context/actions/search/storeSearch";
import { distance } from "@Lib/distance";

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    "use strict";

    if (search instanceof RegExp) {
      throw TypeError("first argument must not be a RegExp");
    }
    if (start === undefined) {
      start = 0;
    }
    return this.indexOf(search, start) !== -1;
  };
}

const Results = () => {
  const { searchState, searchDispatch } = useContext(GlobalContext);

  const [results, setResults] = useState({
    placeFilterDone: false,
    companyFilterDone: false,
    input: searchState.input.data,
    list: searchState.details.data,
    qualities: [],
    landingspage: searchState.input.data.landingspage,
    landingspageChecked: false,
    inputGiven: searchState.input.data.userInput,
    sorted: "relevant",
  });

  useEffect(() => {
    if (
      searchState.input.data.place.length ||
      searchState.input.data.company.Name ||
      searchState.input.data.quality.Name
    ) {
      if (!searchState.latlong.doneLoading) {
        checkLatLong();
      }
      if (searchState.latlong.doneLoading) {
        if (!results.placeFilterDone) {
          results.input.place.length
            ? filterOnPlace()
            : setResults({ ...results, placeFilterDone: true });
        } else if (!results.companyFilterDone) {
          filterOnCompany();
        } else if (!searchState.qualities.doneLoading) {
          checkLandingsPage();
          setQualities(searchDispatch, [...new Set(results.qualities)]);
          storeSearch(searchState, results.list.length);
        }
      }
    } else if (!searchState.qualities.doneLoading) {
      const qualitiesUsed = [
        ...new Set(
          searchState.details.data.map((company) => company.Kwaliteitreg).flat()
        ),
      ];

      setQualities(
        searchDispatch,
        searchState.filterData.data.Kwaliteitsregelingen.filter(
          (quality) => qualitiesUsed.indexOf(quality.Id) > -1
        ).map((q) => q.Id)
      );
    }
  }, [results, searchState.latlong.doneLoading]);

  function checkLatLong() {
    const inputName = results.input.place;
    const zip = parseInt(inputName.substring(0, 4));
    if (isNaN(zip)) {
      setLatLong({}, searchDispatch);
    } else {
      if (inputName.indexOf(".") > 0) {
        setLatLong(
          {
            lat: inputName.split(" ")[0],
            lng: inputName.split(" ")[1],
          },
          searchDispatch
        );
      } else {
        getLatLong(zip)(searchDispatch);
      }
    }
  }

  function filterOnPlace() {
    const keyForLocation = "Loc.";
    let inputName = results.input.place;

    if (searchState.latlong.data && searchState.latlong.data.lat) {
      const distanceRadius = searchState.input.data.distance;
      const lat = searchState.latlong.data.lat;
      const long = searchState.latlong.data.lng;

      setResults({
        ...results,
        placeFilterDone: true,
        list: results.list.filter(
          (value) => distance(lat, long, value.Lat, value.Lng) < distanceRadius
        ),
      });
    } else {
      inputName =
        inputName.toLowerCase() === "den haag" ? "'s-Gravenhage" : inputName;
      inputName =
        inputName.toLowerCase() === "den bosch"
          ? "'s-Hertogenbosch"
          : inputName;

      setResults({
        ...results,
        placeFilterDone: true,
        list: results.list.filter(
          (company) =>
            company[keyForLocation].split(",")[1].toLowerCase().trim() ===
            inputName.toLowerCase().trim()
        ),
      });
    }
  }

  function filterOnQuality() {
    if (results.input.quality.Id) {
      return results.list.filter(
        (company) => company.Kwaliteitreg.indexOf(results.input.quality.Id) > -1
      );
    } else {
      return results.list;
    }
  }

  function filterOnCompany() {
    let wildcard = {
      spec: [],
      quality: [],
      company: [],
    };

    const category = results.input.company.searchCategory;
    let listFiltered = filterOnQuality();
    let specTempList = listFiltered;
    let specTempListOfSpecs = [];
    let specTempListOfSpecsB = [];
    let allSpecIds = [];
    let uniqueList = [];
    let seenList = new Set();
    switch (category) {
      case "company":
        listFiltered = listFiltered.filter(
          (company) =>
            company.Name.trim().toLowerCase() ===
            results.input.company.Name.trim().toLowerCase()
        );
        break;
      case "specialism":
        if (typeof results.input.company.Id === "string") {
          results.input.company.Id.split(",").map((specid) => {
            specTempList.map((company) => {
              company.Specialismen.indexOf(parseInt(specid)) > -1 ||
              company.Kwaliteitreg.indexOf(parseInt(specid)) > -1
                ? specTempListOfSpecs.push(company)
                : null;
            });
          });
          specTempList.map((company) => {
            results.input.company.data.map((dataId) => {
              company.Kwaliteitreg.indexOf(dataId) > -1
                ? specTempListOfSpecsB.push(company)
                : null;
            });
          });

          specTempList = Array.from(
            new Set(specTempListOfSpecs.concat(specTempListOfSpecsB))
          );
        } else if (Array.isArray(results.input.company.Id)) {
          results.input.company.Id.map((specid) => {
            specTempList.map((company) => {
              company.Kwaliteitreg.indexOf(parseInt(specid)) > -1
                ? specTempListOfSpecs.push(company)
                : null;
            });
          });
          specTempList = specTempListOfSpecs;
        } else {
          if (!results.input.company.Id) {
            results.input.company.data.map((specid) => {
              specTempList.map((company) => {
                company.Kwaliteitreg.indexOf(parseInt(specid)) > -1
                  ? specTempListOfSpecs.push(company)
                  : null;
              });
            });
            specTempList = specTempListOfSpecs;
          } else {
            specTempList = specTempList.filter(
              (company) =>
                company.Specialismen.indexOf(results.input.company.Id) > -1
            );
          }
        }

        listFiltered =
          specTempList.length === 0
            ? listFiltered.filter(
                (company) =>
                  company.Kwaliteitreg.indexOf(results.input.company.Id) > -1
              )
            : specTempList;

        listFiltered.map((company) => {
          const qualitiesWithSpec = results.input.company.data;
          let totalRelevantHits = 0;
          company.Kwaliteitreg.map((quality) => {
            qualitiesWithSpec.map((qId) =>
              qId === quality ? totalRelevantHits++ : null
            );
          });
          company.relevantScore = totalRelevantHits;

          return company;
        });
        uniqueList = listFiltered.reduce((acc, item) => {
          if (!seenList.has(item.Id)) {
            seenList.add(item.Id, item);
            acc.push(item);
          }
          return acc;
        }, []); //converting back to array from mapobject
        listFiltered = uniqueList;

        break;
      case "wildcard":
        wildcard.spec = [
          ...new Set(
            searchState.filterData.data.MappingKeywordAttribute.filter(
              (specialism) =>
                specialism.Name.trim()
                  .toLowerCase()
                  .indexOf(results.input.company.Name.trim().toLowerCase()) > -1
            )
              .map((spec) => {
                return typeof spec.Id === "string"
                  ? spec.Id.split(",").map((id) => parseInt(id))
                  : spec.Id;
              })
              .flat()
          ),
        ];

        wildcard.quality = [
          ...new Set(
            searchState.filterData.data.Kwaliteitsregelingen.filter(
              (quality) =>
                quality.Name.trim()
                  .toLowerCase()
                  .indexOf(results.input.company.Name.trim().toLowerCase()) > -1
            ).map((quality) => quality.Id)
          ),
        ];

        listFiltered = listFiltered.filter((company) => {
          if (
            company.Specialismen.filter(
              (element) => wildcard.spec.indexOf(element) > -1
            ).length > 0 ||
            company.Kwaliteitreg.filter(
              (element) => wildcard.quality.indexOf(element) > -1
            ).length > 0 ||
            company.Name.trim()
              .toLowerCase()
              .indexOf(results.input.company.Name.trim().toLowerCase()) > -1
          ) {
            return company;
          }
          if (company.AltName) {
            return company.AltName.trim()
              .toLowerCase()
              .indexOf(results.input.company.Name.trim().toLowerCase()) > -1
              ? company
              : null;
          }
        });
        break;
    }

    const sortingBy = category === "specialism" ? "relevant" : "";

    const filterList = searchState.latlong.data
      ? searchState.latlong.data.lat
        ? sortResultList("distance", listFiltered)
        : sortResultList(sortingBy, listFiltered)
      : sortResultList(sortingBy, listFiltered);

    setResults({
      ...results,
      companyFilterDone: true,
      list: filterList,
      qualities: [
        ...new Set(listFiltered.map((company) => company.Kwaliteitreg).flat()),
      ],
    });
  }

  function sortResultList(sortBy, data) {
    let tempListA, tempListB, tempListC;
    let sortedResults;
    let lat, long, dataToSort;
    switch (sortBy) {
      case "name":
        sortedResults = results.list.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        break;
      case "rating":
        tempListA = results.list
          .filter(
            (result) =>
              result.RateUrl === null ||
              result.RateUrl.indexOf("klantenvertellen") === -1
          )
          .sort((a, b) => {
            b.Rate = b.Rate === null ? "" : b.Rate;
            a.Rate = a.Rate === null ? "" : a.Rate;
            return b.Rate - a.Rate;
          });

        tempListB = results.list
          .filter(
            (result) =>
              result.RateUrl !== null &&
              result.RateUrl.indexOf("klantenvertellen") > -1
          )
          .sort((a, b) => {
            b.Rate = b.Rate === null ? "" : b.Rate;
            a.Rate = a.Rate === null ? "" : a.Rate;
            return b.Rate - a.Rate;
          });

        tempListC = tempListB
          .filter((results) => results.RateAmount >= 5)
          .sort((a, b) => {
            return b.Rate - a.Rate;
          });

        tempListB = tempListB
          .filter((results) => results.RateAmount < 5)
          .sort((a, b) => {
            return b.Rate - a.Rate;
          });

        sortedResults = [
          ...tempListC,
          ...tempListB,
          { Name: "break", resultsLeft: tempListA.length },
          ...tempListA,
        ];
        break;
      case "distance":
        dataToSort = data ? data : results.list;
        lat = searchState.latlong.data.lat;
        long = searchState.latlong.data.lng;
        sortedResults = dataToSort
          .map((value) => {
            value.distance = distance(lat, long, value.Lat, value.Lng);
            return value;
          })
          .sort((a, b) => a.distance - b.distance);
        break;
      case "relevant":
        dataToSort = data ? data : results.list;
        results.input.company.searchCategory === "specialism"
          ? (sortedResults = dataToSort.sort(
              (a, b) => b.relevantScore - a.relevantScore
            ))
          : (sortedResults = dataToSort.sort(
              (a, b) => b.Kwaliteitreg.length - a.Kwaliteitreg.length
            ));
        break;
      default:
        /*dataToSort = data ? data : results.list;
        sortedResults = dataToSort.sort(
          (a, b) => b.Kwaliteitreg.length - a.Kwaliteitreg.length
        );*/
        // rating sort
        tempListA = results.list
        .filter(
          (result) =>
            result.RateUrl === null ||
            result.RateUrl.indexOf("klantenvertellen") === -1
        )
        .sort((a, b) => {
          b.Rate = b.Rate === null ? "" : b.Rate;
          a.Rate = a.Rate === null ? "" : a.Rate;
          return b.Rate - a.Rate;
        });

      tempListB = results.list
        .filter(
          (result) =>
            result.RateUrl !== null &&
            result.RateUrl.indexOf("klantenvertellen") > -1
        )
        .sort((a, b) => {
          b.Rate = b.Rate === null ? "" : b.Rate;
          a.Rate = a.Rate === null ? "" : a.Rate;
          return b.Rate - a.Rate;
        });

      tempListC = tempListB
        .filter((results) => results.RateAmount >= 5)
        .sort((a, b) => {
          return b.Rate - a.Rate;
        });

      tempListB = tempListB
        .filter((results) => results.RateAmount < 5)
        .sort((a, b) => {
          return b.Rate - a.Rate;
        });

      sortedResults = [
        ...tempListC,
        ...tempListB,
        { Name: "break", resultsLeft: tempListA.length },
        ...tempListA,
      ];
    }

    return sortedResults;
  }

  function sortResultsListByOption(e) {
    const breakIndex = results.list.filter((result, index) => {
      if (result.Name === "break") {
        results.list.splice(index, 1);
      }
    });
    const sortedResults = sortResultList(
      e.target.selectedOptions[0].getAttribute("data-sort")
    );

    setResults({
      ...results,
      list: sortedResults,
      sorted: e.target.selectedOptions[0].getAttribute("data-sort"),
    });
  }

  function activateSearch(e) {
    process.env.Module === "dev"
      ? history.push("/membersearch/results")
      : (window.location.href =
          "https://www.technieknederland.nl/ledenzoek-resultaat");
  }

  function checkLandingsPage() {
    let shuffledList = results.landingspage ? [] : results.list;
    if (!results.landingspageChecked) {
      if (results.landingspage) {
        const randomDiv = document.querySelector("#random");
        let showAll = false;
        if (randomDiv) {
          showAll = randomDiv.innerHTML.trim() == 0 ? false : true;
        }
        const nums = new Set();

        if (showAll === false) {
          if (results.list.length > 2) {
            while (nums.size !== 3) {
              nums.add(Math.floor(Math.random() * results.list.length));
            }
            [...nums].map((index) => {
              shuffledList.push(results.list[index]);
            });
          }
        } else {
          shuffledList = results.list.sort((a, b) =>
            a.Name.toLowerCase() > b.Name.toLowerCase()
              ? 1
              : b.Name.toLowerCase() > a.Name.toLowerCase()
              ? -1
              : 0
          );
        }
      }
    }

    setResults({
      ...results,
      landingspageChecked: true,
      list: shuffledList,
    });
  }

  return (
    <section className="search-results">
      <div className="search-results-header">
        {!results.companyFilterDone && results.inputGiven && (
          <p>Bezig met laden</p>
        )}
        {!results.inputGiven && !results.landingspage && (
          <p>Voer een zoekopdracht in</p>
        )}
        {results.companyFilterDone && !results.landingspage && (
          <>
            <div className="total-results">
              <span>
                <strong>
                  {results.sorted === "rating" ? (
                    <>{results.list.length - 1}</>
                  ) : (
                    <>{results.list.length}</>
                  )}
                </strong>
                {results.sorted === "rating" ? (
                  <>
                    {" "}
                    {results.list.length === 0
                      ? " resultaten - wijzig uw zoekopdracht"
                      : results.list.length - 1 > 1
                      ? " resultaten"
                      : " resultaat"}
                  </>
                ) : (
                  <>
                    {results.list.length === 0
                      ? " resultaten - wijzig uw zoekopdracht"
                      : results.list.length > 1
                      ? " resultaten"
                      : " resultaat"}
                  </>
                )}
              </span>
            </div>
            {results.list.length > 1 && !results.landingspage && (
              <div className="sort-by">
                <span>Sorteren op:</span>
                <select onChange={(e) => sortResultsListByOption(e)}>
                  <option data-sort="rating">Waardering</option>
                  <option data-sort="relevant">Relevantie</option>
                  {searchState.latlong.data.lat && (
                    <option data-sort="distance" selected>
                      Afstand
                    </option>
                  )}
                  <option data-sort="name">Naam</option>
                </select>
              </div>
            )}
          </>
        )}
      </div>
      {results.list.length &&
        results.companyFilterDone &&
        results.landingspageChecked && (
          <ResultsList
            list={results.list}
            qualities={searchState.filterData.data.Kwaliteitsregelingen}
          />
        )}
      {results.landingspage && (
        <a className="btn" onClick={activateSearch}>
          Vind een vakman
        </a>
      )}
    </section>
  );
};

export { Results };
