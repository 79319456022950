import { baseUrl, token, placesFromStorage } from "@Constants";
import {
  PLACES_LOADING,
  PLACES_LOAD_ERROR,
  PLACES_LOAD_SUCCESS,
} from "@Constants/actionTypes";
import { CONNECTION_ERROR } from "@Constants/API";

require("es6-promise").polyfill();
require("isomorphic-fetch");

export const getPlaces = (path) => (dispatch) => {
  dispatch({
    type: PLACES_LOADING,
  });
  if (placesFromStorage) {
    setPlaces(dispatch, JSON.parse(placesFromStorage));
  } else {
    fetch(baseUrl + path + "?token=" + token)
    .then((response) => response.json())
    .then((responseData) => {
      setPlaces(dispatch, responseData.data.Places);
      setPlacesStorage(responseData.data.Places);
    })
    .catch((err) => {
      dispatch({
        type: PLACES_LOAD_ERROR,
        payload: err.data ? err.data : CONNECTION_ERROR,
      });
    });
  }
  
};

const setPlaces = (dispatch, data) => {
  dispatch({
    type: PLACES_LOAD_SUCCESS,
    payload: data,
  });
};

const setPlacesStorage = (data) => {
  localStorage.setItem("TNLZSPlaces", JSON.stringify(data));
};
