import { baseUrl, token, detailsFromStorage } from "@Constants";
import {
  DETAILS_LOADING,
  DETAILS_LOAD_ERROR,
  DETAILS_LOAD_SUCCESS,
} from "@Constants/actionTypes";
import { CONNECTION_ERROR } from "@Constants/API";

require("es6-promise").polyfill();
require("isomorphic-fetch");

export const getDetails = (path) => (dispatch) => {
  dispatch({
    type: DETAILS_LOADING,
  });
  if (detailsFromStorage) {
    setDetails(dispatch, JSON.parse(detailsFromStorage));
  } else {
    fetch(baseUrl + path + "?token=" + token)
      .then((response) => response.json())
      .then((responseData) => {
        responseData.data.CompanyDetails.map(company => {
          company.Kwaliteitreg = company.Kwaliteitreg ? company.Kwaliteitreg.split(",").map(quality => parseInt(quality)) : [];
          company.Specialismen = company.Specialismen ? company.Specialismen.split(",").map(spec => parseInt(spec)) : [];
        });
        setDetails(dispatch, responseData.data.CompanyDetails);
        setDetailsStorage(responseData.data.CompanyDetails);
      })
      .catch((err) => {
        dispatch({
          type: DETAILS_LOAD_ERROR,
          payload: err.data ? err.data : CONNECTION_ERROR,
        });
      });
  }
};

const setDetails = (dispatch, data) => {
  dispatch({
    type: DETAILS_LOAD_SUCCESS,
    payload: data,
  });
};

const setDetailsStorage = (data) => {
  sessionStorage.setItem("TNLZSDetails", JSON.stringify(data));
};
