import React from "react";

const Certificates = ({ certificates }) => {
  const total = certificates.length;
  if (total > 3) {
    return (
      <>
        {certificates.map((certificate, index) => (
          <>
            {index < 3 ? (
              <img
                key={`${index}certificate.Name`}
                src={certificate.Logo}
                title={certificate.Name}
                width="32"
              />
            ) : (
              ""
            )}
          </>
        ))}
        <span className="more-certificates">
          (+{total-3})
        </span>
      </>
    );
  } else {
    return (
      <>
        {certificates.map((certificate, index) => (
          <img
            key={`${index}certificate.Name`}
            src={certificate.Logo}
            title={certificate.Name}
            width="32"
          />
        ))}
      </>
    );
  }
};

export { Certificates };
/*
{certificates.map((certificate, index) =>
  {index < 3 && (
    <span key={index}>{certificate}</span>
  )}
)}
*/
