export function getStorageValue(key, typeOfStorage) {
  return typeOfStorage === "local"
    ? localStorage.getItem(key)
    : sessionStorage.getItem(key);
}

export function setStorageValue(key, data, typeOfStorage) {
  typeOfStorage === "local"
    ? localStorage.setItem(key, data)
    : sessionStorage.setItem(key, data);
}
