import {
  QUALITIES_LOADING,
  QUALITIES_LOAD_SUCCESS,
} from "@Constants/actionTypes";

export const setQualities = (dispatch, data) => {
  dispatch({
    type: QUALITIES_LOAD_SUCCESS,
    payload: data
  });

}