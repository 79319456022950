import { baseUrl, token } from "@Constants";
import {
  LAT_LONG_LOAD_SUCCESS,
} from "@Constants/actionTypes";
import { CONNECTION_ERROR } from "@Constants/API";

const getLatLong = (zipcode) => (dispatch) => {
  console.log('getLatLong');
  fetch(baseUrl + "GetLatLong.aspx?postalCode=" + zipcode + "&token=" + token)
    .then((response) => response.json())
    .then((responseData) => {
      console.log('ll', responseData.data.LatLong[0]);
      dispatch({
        type: LAT_LONG_LOAD_SUCCESS,
        payload: responseData.data.LatLong ? responseData.data.LatLong[0] : null
      });
    });
};

const setLatLong = (data, dispatch) => {
  dispatch({
    type: LAT_LONG_LOAD_SUCCESS,
    payload: data
  });
};

export { getLatLong, setLatLong };